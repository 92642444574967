import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
const API_URL = environment.apiUrl + "/vehicleRoutes";
@Injectable()
export class VehicleService {
  constructor(private http: HttpClient) { }
  getPage() {
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  deactiveVehicle(id) {
    return this.http
      .post(API_URL + "/deactive/" + id, id)
      .pipe(map(res => res));
  }
  activeVehicle(id) {
    return this.http.post(API_URL + "/active/" + id, id).pipe(map(res => res));
  }
  getInfo(id) {
    return this.http.get(API_URL + "/info/" + id).pipe(map(res => res));
  }
  getcount() {
    return this.http.get(API_URL + "/totalCount/").pipe(map(res => res));
  }
  getCategory() {
    return this.http.get(API_URL + "/category").pipe(map(res => res));
  }
  getBodyType() {
    return this.http.get(API_URL + "/bodytype").pipe(map(res => res));
  }
  getAmenities() {
    return this.http.get(API_URL + "/amenities").pipe(map(res => res));
  }
  edit(id) {
    return this.http.get(API_URL + "/edit/" + id).pipe(map(res => res));
  }
  update(page, id) {
    const obj = {
      category: page.category,
      status: page.status,
      user_id: page.user_id,
      inspectionon: page.datepicker,
      amenitites: page.amenitites,
      vehicle_name:page.vehicle_name,
      vehicle_number:page.vehicle_number,
      body_type:page.body_type,
      available_seats:page.available_seats,
      book_date:page.book_date,
      book_no:page.book_no,
      insurance_date:page.insurance_date,
      insurance_no:page.insurance_no,
      licence_date:page.licence_date,
      licence_no:page.licence_no,
      licenserejection:page.licenserejection,
      bookrejection:page.bookrejection,
      insurancerejection:page.insurancerejection,
      vehicleimagerejection:page.vehicleimagerejection
    };
    return this.http.post(API_URL + "/update/" + id, obj).pipe(map(res => res));
  }
}
