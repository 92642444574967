// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  domain: "",
  apiUrl: 'https://ryde.us.com:3000',
  socketUrl: 'https://ryde.us.com:8081',
  googleapiKey: "AIzaSyAeAraE1ypjgQI4MZc5uSVU1YlD7D5q6cs",
  googleloginClientid: "869325238160-ls7pc63spb4275lud9lmkfnhl4iq8mui.apps.googleusercontent.com",
  facebookloginClientid: "1455077828183651",
  firebase: {
    apiKey: "AIzaSyDwkBh0HD0Pxt6rCYuO-nrU98r2Wq80xD0",
    authDomain: "ryde-platform-1568201934216.firebaseapp.com",
    databaseURL: "https://ryde-platform-1568201934216.firebaseio.com",
    projectId: "ryde-platform-1568201934216",
    storageBucket: "ryde-platform-1568201934216.appspot.com",
    messagingSenderId: "869325238160",
    appId: "1:869325238160:web:41067d65dd5003c2dc0d15",
    measurementId: "G-K6BHQ4CDB8"
  }
};

